import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import H1 from "../components/H1"
import H2 from "../components/H2"
import Container from "../components/Container"
import RoundedImage from "../components/RoundedImage"
import { graphql } from "gatsby"
import P from "../components/P"
import theme from "../themes"
import { Grid } from "@material-ui/core"
import Team from "../components/Team"
import { useState } from "react"

const UeberUnsPage = ({ data }) => {
  const [teamList, setTeamList] = useState([
    {
      name: "Melanie Häfliger",
      position: "Kita Leitung ",
      experience: "Dip. Fach Frau Betreuung Kind mit ♥️",
      image: data.image2.childImageSharp.fluid,
      roundedImage: true,
    },
    {
      name: "Nicole Jost ",
      position: "Pädagogische/ Gruppen Leitung (ab Oktober)",
      experience: "Dip. Fach Frau Betreuung Kind mit ♥️",
      image: data.image4.childImageSharp.fluid,
      roundedImage: true,
    },
    {
      name: "Leo",
      position: "Motivaitons-Coach",
      experience: "Ein ♥️ für Kinder",
      image: data.image3.childImageSharp.fluid,
      roundedImage: true,
    },
    {
      name: "Sangi Bellarmin",
      position: "Praktikantin ",
      experience: "",
      image: data.image5.childImageSharp.fluid,
      roundedImage: true,
    },
    {
      name: "Marusca Puello",
      position: "Externe Administration  ",
      experience: "",
      image: data.image6.childImageSharp.fluid,
      roundedImage: true,
    },
  ])

  return (
    <Layout>
      {console.log(data)}

      <SEO title="Über uns" />

      <Section>
        <Container
          background={data.image1.childImageSharp.fluid.src}
          backgroundSize={"cover"}
          backgroundPosition={"35% 100%"}
          backgroundPositionSm={"29% 100%"}
          height={"100vh"}
          heightMd={"600px"}
        >
          <div
            style={{
              background: theme.main.colors.white,
              padding: "16px",
              position: "absolute",
              top: "40px",
              left: "0",
            }}
          >
            <H1>Über uns</H1>
            <P>Die Gesichter hinter der Kita.</P>
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <P>
            Unsere Kita wird mit viel Freude und Fantasie geführt. Wir sind ein
            professionelles Team, das gut miteinander harmoniert. Jeder von uns
            bringt eigene Werte und Erfahrungen in den Prozess mit ein. Wir sind
            multikulturell und können in verschiedenen Sprachen kommunizieren.
            Wir sehen die Kinder als gleichberechtigte Partner und gestalten mit
            ihnen einen lebensfrohen Alltag.
          </P>
        </Container>
      </Section>

      <Section>
        <Container>
          <Grid container spacing={4}>
            <H2 textAlign={"center"}>Team</H2>

            {teamList.map(team => {
              if (team.roundedImage) {
                return (
                  <Grid item xs={12} md={6} style={{ marginBottom: "80px" }}>
                    <RoundedImage
                      width={"100%"}
                      widthSm={"80%"}
                      widthMd={"40%"}
                      margin={"0 auto"}
                      fluid={team.image}
                    />
                    <H2 textAlign={"center"}>{team.name}</H2>
                    <P
                      margin={"0px"}
                      marginSm={"0px"}
                      marginMd={"0px"}
                      textAlign={"center"}
                    >
                      {team.position}
                    </P>
                    <P textAlign={"center"}>{team.experience}</P>
                  </Grid>
                )
              } else {
                return <>Notrounded</>
              }
            })}
          </Grid>
        </Container>
      </Section>
    </Layout>
  )
}

export default UeberUnsPage

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "kid_with_egg.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    image2: file(relativePath: { eq: "melanie_haefliger.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    image3: file(relativePath: { eq: "leo.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    image4: file(relativePath: { eq: "nicole_jost.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    image5: file(relativePath: { eq: "sangi_bellarmin.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    image6: file(relativePath: { eq: "marusca_puello.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
