import styled, { css } from "styled-components"
import Img from "gatsby-image"
import theme from "../../themes"
import { device } from "../../themes/device"

const RoundedImage = styled(Img)`
  position: relative;
  width: ${props => (props.width ? props.width : "100%")};
  margin: ${props => (props.margin ? props.margin : "auto")};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "50%")};
  top: 0;
  display: block;
  margin-bottom: 40px;
  padding: 0;

  @media ${device.tablet} {
    width: ${props => (props.widthSm ? props.widthSm : "100%")};
  }

  @media ${device.laptop} {
    width: ${props => (props.widthMd ? props.widthMd : "100%")};
  }

  ${props =>
    props.alignRight &&
    css`
      right: -20%;
    `}
`
export default RoundedImage
